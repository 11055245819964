
import {defineComponent} from "vue";

export default defineComponent({
    emits: ['close', 'submit'],
    props: {
        title: {
            type: String, required: false
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
})
